

/* Navbar */
/* Navbar */

/* Admin Nav  */

.usernav nav {
  background-color: var(--light-color) !important;
  transition: 0.5s ease !important;
  padding: 0.5rem 1rem !important;
  z-index: 1000 !important;
  text-align: left !important;
}

.usernav .navbar-brand {
  width: 100% !important;
  padding: 1rem !important;
  background-color: var(--pry-color);
  margin-bottom: 1rem !important;
}
.title-text {
  margin-top: 0.7rem !important;
  font-size: 17px !important;
  text-transform: uppercase !important;
}

.gold {
  background-color: #f0cd07 !important;
}
/* Dropdown */
.usernav .dropdown-menu {
  width: 18rem !important;
}

.usernav .dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: transparent !important;
}

.dropdown-item .small-text {
  font-size: 14px;
}


.dropdown-menu a {
  border-left: transparent 0px solid !important;
}
.dropdown-menu a:hover {
  border-left: transparent 0px solid !important;
}

.dropdown-menu .ppic {
  border-radius: 5rem !important;
  width: 5rem !important;
  height: 5rem !important;
  padding: 0 !important;
  display: flex;
}
.dropdown-menu .ppic img {
  border-radius: 5rem !important;
}

.name-head {
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: 700 !important;
  font-size: large;
  
}

/* Dropdown */

.usernav .active1 {
  border-left: var(--sec-color) 4px solid !important;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: var(--sec-color) !important;
  transition: 0.1s ease !important;
}

.usernav nav button.navbar-toggler {
  background-color: none !important;
}

.usernav .navbar-toggler-icon {
  background: none !important;
}

.usernav nav .nav-item.top-nav a {
  width: 100% !important;
  font-weight: 600;
  font-size: 13px !important;
  color: var(--light-bold-color);
  border-left: transparent 4px solid;
}

.usernav nav .nav-item {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  margin-left: 0.5rem !important;
}

.usernav nav .nav-item.top-nav a:hover {
  border-left: var(--sec-color) 4px solid !important;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: var(--sec-color) !important;
  transition: 0.1s ease !important;
  background: var(--pry-bold-color);
  border: 0 5px 0 0 !important;
}

.usernav nav .nav-item i .bell {
  left: 14px !important;
  top: 10px !important;
  
}

/* Admin Nav  */

/*Side Navbar */

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0rem;
  left: 0;
  background-color: var(--pry-color);
  overflow-x: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  z-index: 1003 !important;
}
/* Scrollbar  */

.sidenav ::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.sidenav ::-webkit-scrollbar-thumb {
  background: var(--light-color);
  height: 0px !important;
}
/* Scrollbar  */

.sidenav ul li {
  margin-bottom: 1rem;
}

.sidenav .nav-item a {
  color: var(--sec-color);
  font-size: 1rem;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 0rem;
}

.sidenav .closebtn {
  position: absolute;
  right: 15px;
  top: 6px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
}

.name {
  text-align: center !important;
  margin-left: 0.5rem !important;
}

.sidenav h5 {
  font-weight: 100 !important;
  color: aliceblue;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

/*Side Navbar */


/* Bottom Nav Bar */
.bottom-nav {
  display: none !important;
}

.bottom-nav nav {
  z-index: 900 !important;
}

.bottom-nav nav .list-unstyled a {
  color: var(--nav-color);
}
.bottom-nav nav .list-unstyled a:hover {
  color: var(--pry-color);
}

.bottom-nav .small-text {
  font-size: 12px !important;
}

.bottom-nav .active {
  color: var(--pry-color) !important;
  transition: 0.1s ease !important;
}
/* Bottom Nav Bar */

/* Navbar */
/* Navbar */

@media screen and (max-width: 580px) {
  /* Navbar */
  .nav-name {
    display: none !important;
  }

  .title-text {
    display: none !important;
  }
  .upgrade-btn{
    display: none !important;
  }

  .usernav nav {
    background-color: var(--light-color) !important;
    transition: 0.5s ease !important;
    box-shadow: none !important;
    position: absolute !important;
    border-radius: 0 0 1rem 1rem !important;
  }

  .usernav .ico {
    font-size: 1.8rem !important;
  }

  .usernav nav .nav-item i .bell {
  left: 18px !important;
  top: 12px !important;
  
}

  /* Navbar */

  /* Bottom Navbar */
  .bottom-nav {
    display: block !important;
  }
  /* Bottom Navbar */

  /* Side Navbar */

  .sidenav {
    width: 0;
  }

  .usernav .navbar h5 {
    margin-left: -3rem !important;
  }

  .sidenav .closebtn {
    display: block !important;
  }

  h5.name {
    text-align: right !important;
    margin-left: 0.5rem !important;
  }
  /* Side Navbar */
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

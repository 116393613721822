/* Global */
#table {
  overflow-x: scroll !important;
}

/* Form Style */
* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.form {
  margin-top: 2rem;
  padding: 2rem;
}

.input-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
}
.input-lg {
  height: 45px;
}
.input-sm {
  height: 35px;
}

.input-style.input-line {
  border: 0px solid #828282 !important;
  border-bottom: 0px solid #828282 !important;
  border-radius: 1px !important;
  color: var(--dark-color);
  background-color: var(--light-color) !important;
  word-break: break-all !important;
}
.input-style.input-line:hover,
.input-style.input-line:active {
  border: 0px solid #828282 !important;
  border-bottom: 1px solid #828282 !important;
  border-radius: 1px !important;
  color: var(--dark-color);
}
select {
  background: url(../User/img/dropdown.svg) no-repeat right #ddd0 !important;
  -webkit-appearance: none;
  background-position-x: 94% !important;
  background-color: white;
  background-position-y: 52%;
  color: #828282 !important;
}

.textarea-style {
  height: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
}
input:disabled {
  background: #f5f9fd !important;
}
textarea:disabled {
  background: #f5f9fd !important;
}

.input-style:hover,
.input-style:focus {
  border: 1px solid var(--pry-color) !important;
}

.withSign {
  position: relative !important;
  bottom: 3.8rem !important;
  left: 0.6rem;
}

/* Form Style */

/* Login */
.login #togglePassword,
#togglePassword2 {
  font-size: 22px;
}
.login .toggle-eye {
  right: 5%;
  top: 25%;
  position: absolute;
  cursor: pointer;
  font-weight: bolder;
  display: block;
}
/* Login */

/* Modal Props */
.modal section {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.modal-dialog .modal-content {
  border-radius: 1.5rem !important;
}
.modal-header {
  border-bottom: none !important;
}
button.close span {
  display: none !important;
}
.close {
  box-sizing: content-box;
  width: 1em !important;
  height: 1em;
  padding: 0.25em;
  color: var(--dark-color) !important;
  background: transparent url(../User/img/close.svg) 50%/1em auto no-repeat !important;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
/* Modal Props */

/* Full Page Loader */
.app {
  width: 100%;
  height: 100vh;
  background: #f1f6fdb8;
  display: grid;
  place-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}
/* Full Page Loader */

.hide {
  display: none !important;
}
.show {
  display: block !important;
}

/* Global */

/* Notifications */
.notification {
  border: 2px var(--pry-bold-color) solid;
}
.notification-read {
  color: #909090;
}
/* Notifications */

/* Dashboard  */
.main-dash {
  padding-left: 200px !important;
  margin-top: 5rem !important;
  padding-bottom: 5rem !important;
  background-color: var(--sec-color) !important;
}

/* Dashboard  */

/* Sales Record */
.sales-record select {
  background-color: white !important;
}

.css-1s2u09g-control {
  width: 100% !important;
  padding: 6px 0px !important;
  margin: 8px 0 !important;
  border: 1px solid #828282 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 13px !important;
  line-height: 24px;
  color: var(--dark-color) !important;
  box-shadow: none !important;
  margin-bottom: 0.5rem !important ;
}

.css-1pahdxg-control {
  width: 100% !important;
  padding: 6px 0px !important;
  margin: 8px 0 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 13px !important;
  line-height: 24px;
  color: var(--dark-color) !important;
  box-shadow: none !important;
  margin-bottom: 0.5rem !important ;
}

/* Slider */
/* Slider */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sec-bold-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--light-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--pry-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--pry-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* Slider */
/* Slider */
/* Sales Record */

/* Print */
.print {
  padding-bottom: 5rem !important;
}
.print th {
  font-size: larger !important;
}
.print td {
  font-size: larger !important;
}
.print .bizlogo {
  width: 3rem !important;
  height: 3rem !important;
  padding: 0 !important;
  display: flex !important;
  margin-top: 1rem !important;
}

.printing {
  height: 100% !important;
  width: 100% !important;
}
/* Print */

/* Print */
.wprint {
  padding-bottom: 5rem !important;
}
.wprint th {
  font-size: 16px !important;
}
.wprint td {
  font-size: 14px !important;
}

/* Print */

/* Settings */

.settings a {
  font-size: smaller !important;
}

.settings ul {
  background-color: var(--light-color);
  width: max-content !important;
  padding: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.settings a.nav-link {
  font-size: 1.2rem;
  font-weight: 500 !important;
  padding: 1rem 2rem;
  color: #828282 !important;
  border-bottom: transparent solid 2px !important;
}

.settings .nav-link.active {
  color: var(--pry-bold-color) !important;
  border-bottom: var(--pry-color) solid 2px !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.settings .nav-link:hover {
  color: var(--pry-color) !important;
  background-color: var(--sec-color) !important;
  border-bottom: transparent solid 2px !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.settings .nav-tab {
  overflow-x: scroll !important;
}

.settings ul {
  margin-bottom: 0rem !important;
}

.settings .nav-tab::-webkit-scrollbar-track {
  border: none;
  background-color: var(--light-color) !important;
  margin: 0 !important;
}
.settings .nav-tab::-webkit-scrollbar-thumb {
  background: var(--sec-bold-color) !important;
  border-radius: 2rem;
}

.settings .ppic {
  border-radius: 5rem !important;
  width: 7rem !important;
  height: 7rem !important;
  padding: 0 !important;
  display: flex !important;
  margin-top: 1rem !important;
}
.settings .ppic img {
  border-radius: 5rem !important;
}

.settings .file-upload {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 4px solid #ffffff;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    var(--pry-color) 50%,
    #ffffff 50%
  );
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 20px;
}
.settings input[type="file"] {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.settings .file-upload:hover {
  background-position: 0 -100%;

  color: var(--pry-color);
}
/* Staff */
/* Staff */
.all-staff h5 {
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
}

.all-staff .list {
  cursor: pointer;
  transition: 0.3s ease;
  padding: 5px;
}
.all-staff .list:hover {
  background: #f5f9fd !important;
  border-radius: 5rem !important;
  padding: 5px;
}
.all-staff .dropdown-menu-right {
  right: auto !important;
  left: 0 !important;
}
.addstaff .dropdown-menu-right {
  right: auto !important;
  left: 0 !important;
}
/* Staff */

.settings .hide {
  display: none !important;
}
.settings .show {
  display: block !important;
}
.login .hide {
  display: none !important;
}
.login .show {
  display: block !important;
}

/* Staff */

/* Billing */
.right-border {
  border-right: 1px var(--pry-color) solid !important;
}

.check-plan {
  transition: ease 1s;
}

/* Billing */

/* Package */
.packages .card:hover {
  transition: linear 0.1s !important;
}
.packages .card:hover {
  background: var(--pry-color) !important;
  color: var(--light-color) !important;
  box-shadow: 2px 10px 20px 0px #00000040, 0 3px 20px 0px #00000000 !important;
  transform: scale(1.03);
}
.packages .card:hover h3,
.packages .card:hover h5,
.packages .card:hover p {
  color: var(--light-color) !important;
}
.packages .card:hover i {
  background: var(--pry-bold-color) !important;
}
.packages .card:hover hr {
  border: 1px solid var(--light-color) !important;
}
.packages .card:hover button {
  background-color: var(--light-color) !important;
}
/* Package */

/* Settings */
/* Settings */

/* REport */
/* REport */
.report .first {
  border-radius: 0.7rem 0.7rem 0 0;
}
.report .second {
  border-radius: 0 0 0.7rem 0.7rem;
  height: 14rem;
}

.report img {
  position: relative;
  top: 0rem;
}

.underline {
  padding: 1px !important;
  background-color: var(--sec-bold-color) !important;
}

/* REport */
/* REport */

/* Contact Support */
.contact-support {
  position: fixed;
  right: 0;
  bottom: 2rem;
  z-index: 1004 !important;
}
.contact-support i
/* .contact-support .first */ {
  box-shadow: 3px 3px 4px #00000088;
}

.contact-support .first {
  animation-delay: 1s;
  animation: backwards;
  transition: all 1s ease !important;
  z-index: 1005 !important;
}

.support-link {
  background-color: #deebf9a1;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
  position: fixed;
  right: 0;
  bottom: 0;
}
/* Contact Support */

/* User Footer */
.user-footer {
  padding-left: 200px !important;
}
/* User Footer */

/* Media Query for Mobile device */
/* Media Query for Mobile device */
@media screen and (max-width: 780px) {
  table img {
    width: 100% !important;
  }

  /* Billing */
  .right-border {
    border-right: 1px transparent solid !important;
  }

  .packages .card:hover {
    transform: scale(1);
  }

  /* Billing */
  /* REport */
  /* .biz-report .chart .apexcharts-canvas {
    width: 450px !important;
  } */
  /* REport */
}
@media screen and (max-width: 580px) {
  .main-dash {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
    padding-left: 0 !important;
  }

  /* Dashboard  */
  /* .main-dash {
  } */
  table img {
    width: 130% !important;
  }

  /* REport */
  /* .biz-report {
    display: none !important;
  } */

  .biz-report .chart .apexcharts-canvas {
    width: 100% !important;
  }
  /* REport */

  /* Contact Support */
  .contact-support {
    bottom: 6rem;
  }

  /* Contact Support */

  /* Settings */

  .user-settings .container-fluid {
    padding: 0 !important;
  }

  /* Settings */

  /* Global */

  .form {
    margin-top: 2rem;
    padding: 1rem 0rem;
  }
  /* Global */
  .hide {
    display: contents !important;
  }

  /* Footer */
  .user-footer {
    padding-left: 0px !important;
    display: none !important;
  }
  /* Footer */
}
/* Media Query for Mobile device */
/* Media Query for Mobile device */

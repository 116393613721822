/* Showcase */
.showcase {
  margin: 0;
  padding: 0;
  margin-bottom: 5rem;
  background: url(../Homepage/img/bg.jpg), no-repeat center/cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.showcase h3 {
  font-weight: 800 !important;
}
/* Showcase */

/* whatweoffer */
.whatweoffer {
  margin: 0;
  padding: 5rem 0 0 0;
  background: var(--light-color);
  color: var(--dark-color) !important;
  width: 100%;
  overflow-x: hidden;
}

.whatweoffer_1 {
  flex-direction: row-reverse;
}

/* whatweoffer */

/* Get Started */
.get-started {
  padding: 5rem 0;
}

/* Get Started */

/* Other Products */
.products {
  padding: 5rem 0;
}

/* Other Products */

/* Pricing */
.pricing {
  padding: 5rem 0;
}

/* Pricing */

/* Footer */

.footer {
  margin: 0;
  padding-top: 10rem;
  background: url(../Homepage/img/footer_bg.svg), no-repeat center/cover;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
}

.footer .connect i:hover {
  color: var(--pry-color) !important;
  background-color: var(--sec-color) !important;
  border: 1px transparent solid !important;
}
/* Footer */

/* Media Query for Mobile device */
/* Media Query for Mobile device */
@media screen and (max-width: 780px) {
  /* Showcase */
  .showcase h3 {
    font-size: 2.5rem !important;
  }
  /* Showcase */
}
@media screen and (max-width: 580px) {
  /* Showcase */
  .showcase .first {
    padding: 5rem 1rem;
  }

  /* Showcase */
}

/* Media Query for Mobile device */
/* Media Query for Mobile device */
